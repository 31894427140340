a {
    font-family: "Open Sans", sans-serif;
    color: #0047AB;
}

a:visited {
    color: #F6416C;
}

a:hover {
    font-weight: 700;
}

a:active {
    font-weight: 900;
}

