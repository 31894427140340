@import "variables.css";

#Register,
#Login {
  display: grid;
  place-items: start start;
  grid-template-columns: 1fr;

  margin-left: 21vw;
  margin-right: 21vw;

  padding-top: var(--header-height);
  padding-bottom: 4rem;

  z-index: 2;
}

#Register form,
#Login form {
  display: grid;
}

#Register form label,
#Login form label {
   text-align: right; 
}