@import "variables.css";

#resourceCards {
  background-color: var(--footer-background-colour);
  border: 1px solid var(--footer-background-colour);

  padding-bottom: var(--padding-top);
}

#resourceTry {
  padding-top: var(--padding-top);
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
}

#resourceCards .resourceThreeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: var(--cell-gap);
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
}

#resourceCards .resourceThreeColumn .cell1 {
  background-color: #ffffff;
}

#resourceCards .resourceThreeColumn .cell2 {

  background-color: #ffffff;
}

#resourceCards .resourceThreeColumn .cell3 {

  background-color: #ffffff;
}

#resourceCards .resourceThreeColumn .cell1 .text {
  padding: 10px;
}

#resourceCards .resourceThreeColumn .cell2 .text {
  padding: 10px;
}

#resourceCards .resourceThreeColumn .cell3 .text {
  padding: 10px;
}


@media screen and (max-width: 768px) {

  #resourceCards #resourceTry {
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #resourceCards #resourceTry {
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #resourceCards .resourceThreeColumn {
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);

    grid-template-columns: 1fr;
  }

}




