@import "variables.css";

#Footer {
  
  display: grid;
  grid-template-rows: auto auto;
  background-color: var(--footer-background-colour);
}

#Footer .register {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #0047AB;
}

#Footer .register .cell1 {
  display: grid;
  place-items: center start;
  margin-top: 4rem;
  margin-bottom: 4rem;
  margin-left: 21vw;
}

#Footer .register .cell1 h2,
#Footer .register .cell1 p {
  color: white;
}

#Footer .register .cell2 {
  display: grid;
  place-items: center end;

  margin-right: 21vw;
  
  column-gap: 5px;
  grid-auto-flow: column;
}

#Footer .register .cell2 .User-link {
  text-decoration: none;
}

#Footer .register .cell2 .User-link button {

  font-weight:  var(--button-font-weight);
  font-size:  var(--button-font-size);

  padding: 10px;
  width: 175px;

  background: #FFFFFF;
  border-radius: 6px;
  border: none;
}

#Footer .register .cell2 .User-link button:active {  
  color: #0047AB;
}

#Footer .register .cell2 .User-link button:hover {
  cursor: pointer
}

#Footer .contact {
  display: grid;
  place-items: center center;

  margin-left: var(--margin-left);
  margin-right: var(--margin-right);

  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);
}

#Footer .copyright {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  margin-bottom: var(--margin-bottom);


  border-top: 1px solid #C9DCEC;
}

#Footer .copyright .cell1 {
  display: grid;
  place-items: start start;
}

#Footer .copyright .cell2 {
  display: grid;
  place-items: center end;
}

@media screen and (max-width: 768px) {

  #Footer .register {
    place-items: center;
    grid-template-columns: 1fr;
    
    margin-left: 0;
    margin-right: 0;
  }

  #Footer .register .cell1 {
    margin-top: 1rem;
    margin-bottom: 0;
    margin-left: 0;
  }
  
  #Footer .register .cell2 {
    place-items: start center;
  
    margin-right: 0;
    margin-bottom: 2rem;
    grid-auto-flow: column;
  }

  #Footer .contact {  

    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  #Footer .copyright {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-left: 1rem;
    margin-right: 1rem;
  }
 
}
