@import "variables.css";

#homeLogo {
  border-top: 1px solid #F1F6FA;
}

#homeWhoFor,
#homeCards {
  background-color: var(--footer-background-colour);
}

#in-action {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  place-items: center center;
  grid-template-rows: 1fr 1fr;
}

#in-action-play {
  align-items: center;
}

#builders-in-action {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
}

#in-action button {
  margin: 5px;
  width: 100%;

  font-family: "Open Sans", sans-serif;
  font-weight:  var(--button-font-weight);
  font-size:  var(--button-font-size);
  line-height:  var(--button-line-height);

  border-radius: 6px;

  color: #ffffff;
  background-color: #0047AB;
  border: none;
}

#in-action button:hover {
  cursor: pointer
}

h3.homeCards,
p.homeCards,
p.homeRationale,
p.homeResources,
p.homeTraining,
p.homeWhoFor {
  text-align: center;
}

@media screen and (max-width: 768px) {

  #in-action {
    top: 55%;
  }
  
  #builders-in-action {
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1;
  }  
}






