@import "variables.css";

#registerForm form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}

#registerForm form input {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #9497A1;
  padding: 5px;
}

#registerForm form textarea {
  border: 0;
  outline: 0;
  border-bottom: 1px solid #9497A1;
  padding: 5px;
}

#registerForm form .submit {
  display: flex;
  justify-content:flex-end;
}

input[type=submit] {
  border-radius: 6px;

  color: #ffffff;
  background-color: #0047AB;
}

input[type=submit]:active {
  color: #000000;
}

input[type=submit]:hover {
  cursor: pointer
}








