@import "variables.css";

#Content {
  display: grid;

  padding-top: var(--header-height);

  z-index: 2;

  overflow-x: hidden;
}

#Content #breadcrumb {
  margin-top: 1rem;
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
}

#Content .plain {  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}

#Content .oneColumn {  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
}

#Content .oneColumnCentred {
  display: grid;
  place-items: center center;
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}

#Content .twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
}

#Content .twoColumn .cell1 {
  display: grid;
  place-items: start start;
  position: relative;
}

#Content .twoColumn .cell2 {
  display: grid;
  place-items: start start;
  margin-left: 20px;
  position: relative;
}

#Content .twoColumnOneTwo {
  display: grid;
  grid-template-columns: 1fr 2fr;
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  padding-top: var(--padding-top);

  gap: var(--grid-gap);
}

#Content .twoColumnOneTwo .cell1 {
  display: grid;
  place-items: start start;
  position: relative;
}

#Content .twoColumnOneTwo .cell2 {
  display: grid;
  place-items: start start;
  position: relative;
}

#Content .twoColumnTwoOne {
  display: grid;
  grid-template-columns: 2fr 1fr;
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);

  gap: var(--grid-gap);
}

#Content .twoColumnTwoOne .cell1 {
  display: grid;
  place-items: start start;
  position: relative;
}

#Content .twoColumnTwoOne .cell2 {
  display: grid;
  place-items: start start;
  position: relative;
}


#Content .threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
}

#Content .threeColumn .cell1 {
  display: grid;
  place-items: start start;
}

#Content .threeColumn .cell2 {
  display: grid;
  place-items: start start;
  margin-left: 10px;
}

#Content .threeColumn .cell3 {
  display: grid;
  place-items: start start;
  margin-left: 10px;
}

#Content .threeColumnCentred {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: var(--grid-gap);
  
  margin-left: var(--margin-left);
  margin-right: var(--margin-right);
  
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
}

#Content .threeColumnCentred .cell1 {
  display: grid;
  place-items: start center;
  margin-left: var(--cell-gap);
}

#Content .threeColumnCentred .cell2 {
  display: grid;
  place-items: start center;
  margin-left: var(--cell-gap);
}

#Content .threeColumnCentred .cell3 {
  display: grid;
  place-items: start center;
  margin-left: var(--cell-gap);
}

#Content .threeColumnCentred .cell1White {
  display: grid;
  place-items: start center;

  grid-template-rows: 1fr 1fr 3fr;

  background-color: #ffffff;
  padding: var(--cell-gap);
}

#Content .threeColumnCentred .cell2White {
  display: grid;
  place-items: start center;

  grid-template-rows: 1fr 1fr 3fr;

  background-color: #ffffff;
  padding: var(--cell-gap);
}

#Content .threeColumnCentred .cell3White {
  display: grid;
  place-items: start center;

  grid-template-rows: 1fr 1fr 3fr;

  background-color: #ffffff;
  padding: var(--cell-gap);
}

@media screen and (max-width: 768px) {

  #Content #breadcrumb {
    margin-top: var(--margin-top-mobile);  
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #Content .oneColumn {  
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #Content .plain {  
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
    
    padding-top: var(--padding-top-mobile);
    padding-bottom: var(--padding-bottom-mobile);
  }

  #Content .twoColumn {
    display: grid;
    grid-template-columns: 1fr;
    
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
    
    padding-top: var(--padding-top-mobile);
  }
  
  #Content .twoColumn .cell1 {
    display: grid;
    place-items: start start;
    position: relative;
  }
  
  #Content .twoColumn .cell2 {
    display: grid;
    place-items: start start;
    margin-left: var(--margin-left-mobile);
    position: relative;

    padding-top: var(--padding-top-mobile);
  }

  #Content .twoColumnOneTwo {
    display: grid;
    grid-template-columns: 1fr;

    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #Content .twoColumnTwoOne {
    display: grid;
    grid-template-columns: 1fr;

    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-right-mobile);
  }

  #Content .threeColumnCentred {
    grid-template-columns: 1fr;
    
    margin-left: var(--margin-left-mobile);
    margin-right: var(--margin-left-mobile);
  }
}






