@import "variables.css";

p#Menu-burger {
  color: var(--links-colour-link);
  font-size: 4rem;
  margin: 0;
  padding: 0;

  line-height: 0.57;
}

#Menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: var(--header-background-color);
  color: var(--header-text-colour);
}

#Menu-expander {
  position: absolute;
}

.Menu-link {
  font-family: "Open Sans", sans-serif;
  padding: 5px; 
  color: #9497A1;
}

.Menu-link:visited {
  color: #9497A1;
}

.Menu-link:hover {
  color: #46484d;
}

.Menu-link:active {
  color: #151516;
}

#Menu-expander .Menu-link {
  padding: 5px; 

  text-decoration: none;
  color: #b80e1d;
}

#Mobile-main-nav {
  display: grid;
  grid-template-rows: 1rem auto;

  position: fixed;
  top: 0;
  right: -100px;

  z-index: 2;

  padding: 1rem;

  width: 150px;

  background-color: var(--menu-colour);
  transition: transform .8s ease-in-out;
}

#Mobile-main-nav.open {
  transform: translateX(-100px);
}

#Mobile-main-nav.close {
  transform: translateX(150px);
}

#mobile-menu-close {
  color: white;
  text-align: right;
}

#Menu-expander .Mobile-menu-link {
  padding: 5px; 

  text-decoration: none;
  color: #b80e1d;
}

.expander {
  display: grid;
  grid-template-columns: auto auto auto;
}

@media screen and (max-width: 768px) {

  .Menu-link {
    display: grid;
    place-items: center center;
    font-family: "Open Sans", sans-serif;
    color: white;
    text-align: center;
    text-decoration: none;
  }
  
  a.Menu-link:visited {
    color: white;
  }
  
  a.Menu-link:hover {
    color: white;
  }
  
  a.Menu-link:active {
    color: #151516;
  }
  
}