@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Manrope');
  
:root {
  --menu-colour: #0047AB;

  --line-height: 1.5;

  --grid-gap: 20px;
  --cell-gap: 15px;

  --margin-left: 21vw;
  --margin-right: 21vw;

  --margin-top: 2rem;
  --margin-bottom: 2rem;

  --padding-top: 2rem;
  --padding-bottom: 2rem;

  --margin-left-mobile: 1rem;
  --margin-right-mobile: 1rem;

  --margin-top-mobile: 1rem;
  --margin-bottom-mobile: 1rem;

  --padding-top-mobile: 1rem;
  --padding-bottom-mobile: 1rem;

  --button-font-weight: 500;
  --button-font-size: 0.875rem;
  --button-line-height: 1.5rem;
  --button-border-colour: white; 

  --footer-background-colour: #F1F6FA;

  --header-link-colour: #61dafb;
  --link-colour: #61dafb;
  --footer-link-colour: #61dafb;

  --header-height: 200px;
  --content-height: 100vh; 
  --footer-height: 150px;

  --links-colour-link: rgb(87, 150, 223);
  --links-colour-visited: rgb(61, 126, 201);
  --links-colour-hover: red;
  --links-colour-active: rgb(211, 115, 25);

  --table-spacing: 40px 5px;  
}