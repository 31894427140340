@import "variables.css";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0; 
  padding: 0;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Manrope, sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html:focus-within {
  scroll-behavior: smooth;
}

input,
button,
textarea,
select {
  font-family: "Open Sans", sans-serif;
}