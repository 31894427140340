@import "variables.css";

#Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;      

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  min-height: var(--header-height);

  background-color: white;

  opacity: 1;
  z-index: 1;
}

#Header .cell1 {
  display: grid;
  place-items: center start;
  margin-left: var(--margin-left);
}

#Header .cell2 {
  display: grid;
  place-items: center center;
}

#Header .cell3 {
  display: grid;
  place-items: center end;
  
  grid-gap: var(--grid-gap);
  grid-auto-flow: column;
  margin-right: var(--margin-right);
}

#Header .cell3 .User-link {
  padding: 5px; 
  text-decoration: none;
}

#Header .cell3 .User-link button {
  height: 45px;
  width: 4vw;

  font-family: "Open Sans", sans-serif;
  font-weight:  var(--button-font-weight);
  font-size:  var(--button-font-size);
  line-height:  var(--button-line-height);

  border-radius: 6px;

  color: #ffffff;
  background-color: #0047AB;
  border: none;
}

#Header .cell3 .User-link button:active {  
  color: #000000;
}

#Header .cell3 .User-link button:hover {
  cursor: pointer
}

@media screen and (max-width: 768px) {

  #Header {
    grid-template-columns: 1fr 1fr;
  }

  #Header .cell1 {
    place-items: center start;
    margin-left: var(--margin-left-mobile);
  }

  #Header .cell2 {
    place-items: center end;
    margin-right: var(--margin-right-mobile);
  }
}

