@import "variables.css";

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-weight: 800;
  font-size: 1.25rem;
  line-height: var(--line-height);
  color: #000000;
  margin-left: var(--margin-left);
}

h2 {
  font-weight: 800;
  font-size: 3rem;
  line-height: var(--line-height);
  color: #000000;
}

h3 {
  font-weight: 800;
  font-size: 2.4rem;
  line-height: var(--line-height);
  color: #000000;
}

h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: var(--line-height);
  color: #3a3d44;
}

p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: var(--line-height);
  color: #9497A1;
  
  margin: 0 0 1rem 0;
  padding: 0;
}

label {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

code {
  font-family: "Courier New", monospace;
}

span.content-link-title {
  color: var(--text-colour);
  font-size: 1rem
}

span.subText {
  color: var(--text-colour);
  font-size: 1rem;
}

span.superscript {
  font-size: 75%;
  margin: left;
  vertical-align: .5em;
}

ul {
  margin: 0;
  white-space: normal;
}

li {
  color: var(--text-colour);
}

.subText {
  font-size: 1rem;
}

#contactForm form label {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: var(--line-height);
  color: #000000;
}

@media screen and (max-width: 768px) {

  h1 {
    margin-left: var(--margin-left-mobile);
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }
  
}