img.logo {
  height: 160px;
}

img.footer-logo {
  height: 80px;
  padding: 5px;
}

img#story {
  display: grid;
  place-items: center center;
  margin: auto;

  max-height: 100%;
  max-width: 100%;
}

img#play {
  vertical-align: middle;
  max-height: 1.5rem;
  display: inline-block
}

img#northants {
  max-height: 75px;
  display: inline-block;
}

img#sussex {
  max-height: 110px;
  display: inline-block;
}

img#autumnlodge {
  max-height: 75px;
  display: inline-block;
}

img#hop {
  max-height: 110px;
  display: inline-block;
}

img#adre {
  max-height: 75px;
  display: inline-block;
}

img#agescotland {
  max-height: 110px;
  display: inline-block;
}

img#cards {
  max-height: 98px;
  padding: 20px;
}

img#rationale {
  max-height: 98px;
  padding: 20px;
}

img#resources {
  max-height: 98px;
  padding: 20px;
}

img#training {
  max-height: 78px;
  padding: 20px;
}

img#who {
  max-width: 100%;
  height: auto;
}

img#goal {
  max-width: 100%;
  height: auto;
}

img#activity-instructions {
  max-width: 100%;
  height: auto;
}

img#card-set {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 768px) {

  img#northants {
    max-height: 50px;
    display: inline-block;
  }
  
  img#sussex {
    max-height: 73px;
    display: inline-block;
  }

  img.logo {
    height: 50px;
  }

  img.footer-logo {
    height: 25px;
  }
  
}